<template>
  <sdCards>
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">SMTP Details</sdHeading>
        <span>Set Up Your SMTP Information</span>
      </div>
    </template>
        <BasicFormWrapper>
          <a-form layout="horizontal" ref="formRef" :model="formState" :rules="rules" @submit="onSMTPDetailsSubmit">
            <a-row :gutter="25">
               <a-col :xl="12" :lg="12" :xs="24">
                  <a-form-Item label="Host">
                    <a-input
                      required
                      name="smtp_host"
                      placeholder="Enter host"
                      v-model:value="formState.smtp_host"
                    />
                  </a-form-Item>
                  <a-form-Item label="Port">
                    <a-input
                      required
                      type="number"
                      name="smtp_port"
                      placeholder="Enter port number"
                      v-model:value="formState.smtp_port"
                    />
                  </a-form-Item>
                  <a-form-Item label="Username">
                    <a-input
                      required
                      name="smtp_username"
                      placeholder="Enter username"
                      v-model:value="formState.smtp_username"
                    />
                  </a-form-Item>
                  <a-form-Item label="Encryption Type">
                    <a-input
                      required
                      name="encryption_type"
                      placeholder="Enter encryption type"
                      v-model:value="formState.encryption_type"
                    />
                  </a-form-Item>
                </a-col>
                <a-col :xl="12" :lg="12" :xs="24">
                  <a-form-Item label="Password">
                    <a-input
                      required
                      type="password"
                      name="smtp_password"
                      placeholder="Enter password"
                      v-model:value="formState.smtp_password"
                    />
                  </a-form-Item>
                  <a-form-Item label="From Name">
                    <a-input
                      required
                      name="smtp_from_name"
                      placeholder="Enter from name"
                      v-model:value="formState.smtp_from_name"
                    />
                  </a-form-Item>
                  <a-form-Item label="From Email">
                    <a-input
                      required
                      type="email"
                      name="smtp_from_email"
                      placeholder="Enter from email"
                      v-model:value="formState.smtp_from_email"
                    />
                  </a-form-Item>
                </a-col>
            </a-row>
            <div class="setting-form-actions text-center">
              <sdButton size="default" htmlType="submit" type="primary">
                Update
              </sdButton>
            </div>
          </a-form>
        </BasicFormWrapper>
  </sdCards>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { mapActions } from "vuex"
import { BasicFormWrapper } from '../../../styled';
import Notif from "@/services/Notification";

const SMTP = defineComponent({
  name: 'SMTP',
  components: { BasicFormWrapper },
  data() {
    return {
      formState: {
        smtp_host: "",
        smtp_port: "",
        smtp_username: "",
        smtp_password: "",
        smtp_from_name: "",
        smtp_from_email: "",
        encryption_type: "",
      },
      rules: {},
      formRef: ref(),
    };
  },
  computed: {
    smtp: function() {
      return this.$store.getters.smtpDetails;
    },
  },
  beforeMount() {
    const {
      smtp_host = "",
      smtp_port = "",
      smtp_username = "",
      smtp_password = "",
      smtp_from_name = "",
      smtp_from_email = "",
      encryption_type = "",
    } = this.smtp;
    this.formState = {
      ...this.formState,
      smtp_host, smtp_port, smtp_username,
      smtp_password, smtp_from_name, smtp_from_email,encryption_type,
    };
  },
  methods: {
    ...mapActions(["updateSmtpDetails"]),
    handleCancel(e) {
      e.preventDefault();
    },
    async onSMTPDetailsSubmit(e) {
      e.preventDefault();
      try {
        await this.updateSmtpDetails(this.formState);
      } catch (error) {
        if (error?.message) {
          Notif.show(error.message, {type: "error", title: "Error"});
        }
      }
    },
  },
  created() {
    const rules = {};
    for (const [key] of Object.entries(this.formState)) {
      rules[key] = [
        { required: true, message: `Please enter ${key.replace("_", " ")}`, trigger: 'blur' },
      ];
    }
    this.rules = rules;
  }
});

export default SMTP;
</script>
